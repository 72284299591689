import { Injectable } from '@angular/core';
import {
  HttpClient,
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpHeaders,
  HttpInterceptor,
  HttpRequest
} from "@angular/common/http";
import {Observable} from "rxjs";
import {catchError} from "rxjs/operators";
import {Router} from "@angular/router";

@Injectable({
  providedIn: 'root'
})
export class HttpInterceptorService implements HttpInterceptor {
  constructor(private http: HttpClient, private  router: Router) {}

  intercept(request: HttpRequest<any>, next: HttpHandler):Observable<HttpEvent<any>> {

    if (request.url.includes("login") || request.url.includes("signup") || request.url.includes("get-project-poly"))
      return next.handle(request);

    const headers = new HttpHeaders(
        {
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + JSON.parse(<string>sessionStorage.getItem('currentUser'))
        }
    );

    const url = `${request.url}&api_key=xyZapiKeyExample`;
    const newRequest = request.clone({headers})
    return next.handle(newRequest).pipe(catchError(err => {
      if(err instanceof HttpErrorResponse) {
        console.log('error.status: ' + err.status)
        if(err.status === 400) {
          // Handle unauthorized error
          // console.log("Unauthorized");
          // logout() user
        } else if(err.status === 500) {
          // Handler internal server error
          // console.log("Server is not responding.")
          // alert("Try after some time.")
          this.router.navigate(['/'])
        }
        // ......
      }
      return new Observable<HttpEvent<any>>();
    }));
  }
}
