import { Injectable } from '@angular/core';
import {HttpClient, HttpHeaders} from "@angular/common/http";
import {Observable} from "rxjs";
import {map} from "rxjs/operators";
import {User} from "../entities/User";



@Injectable({
  providedIn: 'root'
})
export class UserService {
public jwtToken: string = '';

  constructor(private http: HttpClient) { }

  login(user: User): Observable<any> {
    const headers = new HttpHeaders(
        user ? {
          authorization:'Basic ' + btoa(user.email + ':' + user.password)
        } : {}
    );

    return this.http.get<any> ("http://localhost:8080/api/users/login", {headers:headers}).pipe(
        map((response) =>{
          if(response){
            sessionStorage.setItem('currentUser', JSON.stringify(response.token));
          this.jwtToken = JSON.stringify(response.token);
          }
          return response;
        })
    );
  }
}
