import {Injectable} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {Observable} from "rxjs";
import {Project} from "../entities/Project";

@Injectable({
    providedIn: 'root'
})
export class ProjectService {

    constructor(private http: HttpClient) {
    }

    getAllProjects(): Observable<Array<Project>> {
        return this.http.get<Array<Project>>("http://localhost:8080/api/user/projects");
    }

    findProjectByUuid(uuid: string | null): Observable<Project> {
        return this.http.get<Project>("http://localhost:8080/api/user/projects/edit/" + uuid);
    }
    saveProject(project: Project): Observable<any> {
        console.log('project in servicve: ', project)
        return this.http.post<Project>("http://localhost:8080/api/projects/project/create", project)
    }

    getProjectLocation(searchString: string): Observable<any> {
        return this.http.get("https://nominatim.openstreetmap.org/search?q=" + searchString + "&limit=1&format=json")
    }
 // TODO Implement Geolocation Service
    // curl "https://nominatim.openstreetmap.org/search?q=Zurich&limit=1&format=json" | jq


}
