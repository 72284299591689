import {Geometry} from "ol/geom";

export class Project {
    id!: string;
     title!: string;
     description!: string;
     type!: ProjectType;
     location!: string;
    longitude!: number;
    latitude!: number;
    projectLocation!: any;
    projectArea!: Geometry;
    // private polygon!: object;

}

export enum ProjectType {
    GROUNDWATER_HYDRAULICS = 'GROUNDWATER HYDRAULICS',
    GROUNDWATER_MONITORING = 'GROUNDWATER MONITORING',
    GROUNDWATER_CHEMISTRY = 'GROUNDWATER CHEMISTRY',
    OTHER = 'OTHER'
}

