import {Component, OnInit} from '@angular/core';
import Map from 'ol/Map';
import View from 'ol/View';
import VectorLayer from 'ol/layer/Vector';
import Style from 'ol/style/Style';
import Icon from 'ol/style/Icon';
import OSM from 'ol/source/OSM';
import * as olProj from 'ol/proj';
import TileLayer from 'ol/layer/Tile';
import {Feature} from "ol";
import VectorSource from "ol/source/Vector";
import {ProjectService} from "../../services/project.service";
import {Project} from "../../entities/Project";
import {Fill, Stroke,Text} from "ol/style";
import {GeoJSON} from "ol/format";
import {Router} from "@angular/router";
import {MapService} from "../../services/map.service";
import {AnalyticsService} from "../../services/analytics.service";
import {isNotNullOrUndefined} from "codelyzer/util/isNotNullOrUndefined";



@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {
    map!: Map;
    response: any;
    numberProjects: number = 0;
    projectLocations!: Array<Feature>;
    projectPolygon = new VectorLayer();

    constructor(private projectService: ProjectService,
                private mapService: MapService,
                private analyticsService: AnalyticsService,
                private router: Router) {
        this.analyticsService.getProjectPoly().subscribe(
            res => {
               let vectorSource = new VectorSource({
                    format: new GeoJSON(),
                })
                // If response is valid
                let geojsonFormat = new GeoJSON();

                // reads and converts GeoJSon to Feature Object
                let response = res;
                let featuresCollection = geojsonFormat.readFeatures(response,     {
                    featureProjection: 'EPSG:3857'
                });
                featuresCollection.forEach((feature)=>{


                    console.log('VALUE', feature.getProperties()['value']);

                    feature.setStyle(new Style({
                        stroke: new Stroke({
                            width: 2,
                            color: [0, 0, 255, 1],
                        })
                        ,
                        // fill: new Fill(
                        //     {color: [255, 0, 0, 0.1],}
                        // ),

                        text: new Text({
                            font: '15px "Open Sans", "Arial Unicode MS", "sans-serif"',
                            text: feature.getProperties()['value'].toString().substr(0,4),
                            placement: 'line',
                            fill: new Fill({
                                color: 'red'
                            }),
                        })

                }))})
                console.log('Response Collection: ', res)
                console.log('Loaded Collection: ', featuresCollection)
                    vectorSource.addFeatures(featuresCollection);

                this.projectPolygon.setSource(vectorSource);

            });
        this.projectLocations = new Array<Feature>();
        this.projectService.getAllProjects().subscribe(
            res => {
                this.response = res;
                this.numberProjects = res.length;
                res.forEach(
                    (item: Project) => {
                        let geojsonFormat = new GeoJSON();
                        if (isNotNullOrUndefined(item.projectLocation)) {
                            let feature = geojsonFormat.readFeature(item.projectLocation, {
                                featureProjection: 'EPSG:3857'
                            });
                            console.log(geojsonFormat.writeFeature(feature));
                            feature.setStyle(new Style({
                                image: new Icon({
                                    src: this.mapService.createProjectIconString('blue')
                                })
                            }));
                            console.log('Project Locationas ', this.projectLocations)
                            this.projectLocations.push(feature);
                        }
                        if (!isNotNullOrUndefined(item.projectArea)){
                            let feature2 = geojsonFormat.readFeature(item.projectArea, {
                                featureProjection: 'EPSG:3857'
                            });
                        }

                    }
                )
                this.createMap();
            }
        );
    }


    ngOnInit() {
    }

    createProject() {
        console.log('Go to create Project');
        this.router.navigate(['project']);
    }

    editProject(id: string) {
        console.log('Go to Project detail page ' + id);
        this.router.navigate(['project/', id]);
    }


    createMap() {
        var rasterLayer = new TileLayer({
            source: new OSM()
        })
        this.map = new Map({
            target: 'map',
            layers: [rasterLayer,
                this.projectPolygon,
                this.mapService.createVectorLayer(this.projectLocations)],
            view: new View({
                center: olProj.fromLonLat([7.0785, 51.4614]),
                zoom: 5
            })
        });
    }
}
