<div class="project-container">
    <div class="project">
        <div class="project-header">
            <div class="project-header-line">
                <b>Meine Projekte</b>
                <lib-search-field></lib-search-field>
            </div>
            <div class="break"></div>
            <div class="project-header-line">
                <lib-inline-counter
                        [orientation]="true"
                        label="Anzahl: "
                        [counter]="numberProjects">
                </lib-inline-counter>
                <lib-primary-button
                        variant="primary-button"
                        size="small"
                        label="Neues Projekt erstellen"
                        (click)="createProject()">
                </lib-primary-button>
            </div>
        </div>

        <div class="project-list">
            <lib-project-card *ngFor="let project of response">
                <div class="main-content-row" slot="main">
                    <div class="project-title" slot="main">{{project.title}}</div>
                    <div class="line-break"></div>
                    <div style="color: darkcyan; margin-inline: 10px">{{project.type}}</div>
                    <div class="line-break"></div>
                    <lib-inline-icon [orientation]="false" slot="main" label="Benuzter"
                                     iconPath="user.svg"></lib-inline-icon>
                    <div class="line-break"></div>
                    <lib-inline-icon [orientation]="false" label="{{project.location}}"
                                     iconPath="location.svg"></lib-inline-icon>
                    <div class="line-break"></div>
                    <lib-inline-counter [orientation]="true"
                                        iconPath="longitude.svg"
                                        label="Longitude: "
                                        [counter]="project.longitude"></lib-inline-counter>
                    <lib-inline-counter [orientation]="true"
                                        label="Latitude: "
                                        iconPath="latitude.svg"
                                        [counter]="project.latitude"></lib-inline-counter>
                    <lib-inline-link iconPath="geodatapoint.svg" label="Geodatenpunkte: "
                                     [linkText]="8"></lib-inline-link>
                    <lib-inline-link iconPath="chemistry.svg" label="Analyse: " [linkText]="23"></lib-inline-link>
                </div>
                <div slot="end">
                    <lib-primary-button
                            variant="secondary-button"
                            size="small"
                            label="Details"
                            (click)="editProject(project.id)">
                    </lib-primary-button>
                </div>
            </lib-project-card>
        </div>
    </div>
    <div class="map-view">
        <div id="map"></div>
    </div>

</div>
