import { Injectable } from '@angular/core';
import {Feature} from "ol";
import VectorLayer from "ol/layer/Vector";
import VectorSource from "ol/source/Vector";

@Injectable({
  providedIn: 'root'
})
export class MapService {

  constructor() { }

  createProjectIconString(color: string): string {
    var svg =`<svg width="22" height="36" viewBox="0 0 22 36" fill="none" xmlns="http://www.w3.org/2000/svg"> ` +
        `<path d="M11 16.1832C8.39581 16.1832 6.31248 14.1202 6.31248 11.5414C6.31248 8.96268 8.39581 6.89969 11 6.89969C13.6041 ` +
        `6.89969 15.6875 8.96268 15.6875 11.5414C15.6875 14.1202 13.6041 16.1832 11 16.1832ZM11 0.710693C7.40623 ` +
        `0.710693 4.02081 2.46424 1.98956 5.45559C-0.0416907 8.39536 -0.458358 12.1603 0.843726 15.5127L5.79164 ` +
        `26.3434L10.0625 35.2143C10.2187 35.5753 10.5833 35.7816 11 35.7816C11.4166 35.7816 11.7812 35.5753 ` +
        `11.9375 35.2143L16.2083 26.3434L21.1562 15.5127C22.4583 12.1603 22.0416 8.39536 20.0104 5.45559C17.9791 ` +
        `2.46424 14.5937 0.710693 11 0.710693Z" fill="${color}"/></svg>`
    return "data:image/svg+xml;base64," + btoa(svg);
  }

  createVectorLayer(featureArray: Array<Feature>): VectorLayer{
    let vectorSource = new VectorSource({
      features: featureArray,
    });
    return new VectorLayer({
      source: vectorSource,
    });
  }

}
