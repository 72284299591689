import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import {HomeComponent} from "./components/home/home.component";
import {ProjectDetailComponent} from "./components/project-detail/project-detail.component";
import {ProjectCreateComponent} from "./components/project-create/project-create.component";
import {UserDetailComponent} from "./components/user-detail/user-detail.component";

const routes: Routes = [
  {path: 'home', component: HomeComponent},
  { path: 'project/:id', component: ProjectDetailComponent },
  { path: 'project', component: ProjectCreateComponent },
  { path: 'user', component: UserDetailComponent}
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
