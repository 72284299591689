import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import {GdsComponentLibModule} from 'gds-component-lib';
import { HomeComponent } from './components/home/home.component';
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {HTTP_INTERCEPTORS, HttpClientModule} from "@angular/common/http";
import {RouterModule} from "@angular/router";
import {HttpInterceptorService} from "./auth/http-interceptor.service";
import { ProjectDetailComponent } from './components/project-detail/project-detail.component';
import { ProjectCreateComponent } from './components/project-create/project-create.component';
import { UserDetailComponent } from './components/user-detail/user-detail.component';

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    ProjectDetailComponent,
    ProjectCreateComponent,
    UserDetailComponent
  ],
    imports: [
        BrowserModule,
        AppRoutingModule,
        GdsComponentLibModule,
        ReactiveFormsModule,
        FormsModule,
        HttpClientModule,
        RouterModule

    ],
  providers: [
      {
          provide: HTTP_INTERCEPTORS,
          useClass: HttpInterceptorService,
          multi: true
      }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
