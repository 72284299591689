import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormControl, FormGroup, Validators} from "@angular/forms";
import {UserService} from "./services/user.service";
import {User} from "./entities/User";
import {Router} from "@angular/router";

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit{
  title = 'gds-ui-compnent';
  openUser: boolean = false;
  signupForm!: FormGroup;
  advertising!: boolean;
  hasLoginUser: boolean = false;

 public getFormControl(name: string | number): FormControl{
   return <FormControl>this.signupForm.controls[name];
  }

  constructor(private fb: FormBuilder,
              private userService: UserService,
              private router: Router) {
    this.signupForm = this.fb.group({
      email: new FormControl('',Validators.required),
      password: new  FormControl('',Validators.required)
    });
  }

    ngOnInit(): void {
        this.advertising = sessionStorage.getItem("currentUser") === null;
        if(sessionStorage.getItem("currentUser") === null || undefined){
            this.hasLoginUser = false;
        }
        else {
            this.hasLoginUser = true;
        }
 }


    changeLanguage($event: String) {
    console.log('Language set to: ', $event);
  }

  openModal($event: boolean) {
    if (this.openUser === null || undefined){
        this.openUser = $event;
    }
    else {
        this.openUser = !this.openUser;
    }
    console.log('User Modal value set to: ' + this.openUser);
  }

  cancel() {
    this.openUser = !this.openUser;
    this.signupForm.reset();
  }

  submit() {
    console.log(this.signupForm.value);
    console.log('errors email: ', this.signupForm.controls['email'].errors);
      let user: User;
      user = this.signupForm.value;
    this.userService.login(user).subscribe(
        res => {
            console.log(JSON.stringify(res));
            this.openUser = false;
            this.advertising = false;
            this.hasLoginUser = true;
            this.router.navigate(['/home']).then();
        },
        error => {
            alert(error.message);
        }
    )
  }

    openDropdown() {
        // @ts-ignore
        document.getElementById("mySidenav").style.width = "250px";
        this.openUser = false;
    }

     closeNav() {
        // @ts-ignore
         document.getElementById("mySidenav").style.width = "0";
    }

    logout() {
         sessionStorage.removeItem("currentUser");
    }
}
