import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormControl, FormGroup, Validators} from "@angular/forms";
import {Router} from "@angular/router";
import TileLayer from "ol/layer/Tile";
import OSM from "ol/source/OSM";
import Map from "ol/Map";
import View from "ol/View";
import * as olProj from "ol/proj";
import {Project, ProjectType} from "../../entities/Project";
import {ProjectService} from "../../services/project.service";
import {Geometry, Point} from "ol/geom";
import {fromLonLat} from "ol/proj";
import {GeoJSON} from "ol/format";
import {Feature} from "ol";
import {isNotNullOrUndefined} from "codelyzer/util/isNotNullOrUndefined";
import {MapService} from "../../services/map.service";
import {reset} from "ol/transform";
import Style from "ol/style/Style";
import Icon from "ol/style/Icon";

@Component({
  selector: 'app-project-create',
  templateUrl: './project-create.component.html',
  styleUrls: ['./project-create.component.scss']
})
export class ProjectCreateComponent implements OnInit {


  map!: Map;
  projectForm!: FormGroup;
  projectEnumValues = Object.values(ProjectType)
  projectWizzardState: number = 0;
  projectGeometries!: Array<Feature>;


  constructor(private fb: FormBuilder,
              private router: Router,
              private projectService: ProjectService,
              private mapService: MapService) {
    this.projectForm = this.fb.group({
      title: new FormControl('',Validators.required),
      projectLocation: new  FormControl('',Validators.required),
      longitude: new  FormControl(''),
      latitude: new  FormControl(''),
      type: new FormControl(''),
      description: new FormControl('')
    });

  }

  ngOnInit(): void {
  }

    cancel() {
      this.projectForm.reset();
      this.router.navigate(['/home']).then();
    }

  submit() {
    let project: Project;
    project = this.projectForm.value;
    project.type = this.mapValueToEnum(project.type);
     let projectLocation = new Point([project.longitude, project.latitude]);
     project.location = project.projectLocation;
    project.projectLocation = {"type" : "Point","coordinates" : [project.longitude,project.latitude,2]}

    this.projectService.saveProject(project).subscribe(() => {
      console.log('PROJECT SAVED')
      this.router.navigate(['/home']).then();
    });

  }

  createMap(longitude: number, latitude: number) {
    let projectPoint = {"type" : "Point","coordinates" : [longitude,latitude,2]}
    let geojsonFormat = new GeoJSON();
    if (isNotNullOrUndefined(projectPoint)) {
      let feature = geojsonFormat.readFeature(projectPoint, {
        featureProjection: 'EPSG:3857'});
      feature.setStyle(new Style({
        image: new Icon({
          src: this.mapService.createProjectIconString('red')
        })
      }));
      this.projectGeometries = new Array<Feature>();
    this.projectGeometries.push(feature)    }

    var rasterLayer = new TileLayer({
      source: new OSM()
    })
    this.map = new Map({
      target: 'map',
      layers: [rasterLayer, this.mapService.createVectorLayer(this.projectGeometries)],
      view: new View({
        center: olProj.fromLonLat([longitude, latitude]),
        zoom: 10
      })
    });
  }

  public getFormControl(name: string | number): FormControl{
    return <FormControl>this.projectForm.controls[name];
  }

  setTypeToModel($event: string) {
   this.mapValueToEnum($event);
  }

  mapValueToEnum(value: string): ProjectType{
    const enumValues: any[] =Object.entries(ProjectType);
    enumValues.forEach( item => {
      if (value===item[1]){
        return item[0];
      }
    }
  )
    return ProjectType.OTHER
  }


  next() {
    this.projectService.getProjectLocation(this.projectForm.controls['projectLocation'].value).subscribe(
        res =>{
          this.projectForm.controls['longitude'].setValue(res[0]['lon']);
          this.projectForm.controls['latitude'].setValue(res[0]['lat']);
          this.createMap(res[0]['lon'], res[0]['lat']);
        }
    )
    this.projectWizzardState = 1;
  }

  previous() {
    this.map.dispose();
    this.projectWizzardState = 0;
  }

  uploadProjectArea() {
    console.log('I upload aprojecty area')
  }

  drawProjectArea() {
    console.log('I draw a Polygon')
  }
}
