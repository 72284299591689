import { Injectable } from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {Observable} from "rxjs";
import {GeoJSON} from "ol/format";

@Injectable({
  providedIn: 'root'
})
export class AnalyticsService {

  constructor(private http: HttpClient) {
  }

  getProjectPolygon(): Observable<GeoJSON> {
    return this.http.get<GeoJSON>("http://127.0.0.1:5000/get-project-polygon");
  }
  getProjectPoly(): Observable<any> {
    return this.http.get<Array<any>>("http://127.0.0.1:5000/get-project-poly");
  }
}
