<div class="project-container">
    <div class="project">

        <div *ngIf="projectWizzardState===0" class="project-create-header">
        <h4>Neues Projekt erstellen</h4>
        </div>
        <div *ngIf="projectWizzardState===1" class="project-create-header">
            <h4>Geometrie erstellen</h4>
        </div>
        <form class="project-form" [formGroup]="projectForm" (ngSubmit)="submit()">
            <lib-input-field *ngIf="projectWizzardState===0" label="Projekt Name" inputType="text"
                             [formControlLabel]="getFormControl('title')"
                             fieldLength="65"></lib-input-field>

            <lib-input-field label="Projekt Standort" inputType="text"
                             [formControlLabel]="getFormControl('projectLocation')"

                             fieldLength="65"></lib-input-field>
 <div class="coordinates-row" *ngIf="projectWizzardState===1">
            <lib-input-field label="Langitude" inputType="text"
                             [formControlLabel]="getFormControl('longitude')"
                             fieldLength="80"></lib-input-field>

            <lib-input-field  label="Longitude" inputType="text"
                             [formControlLabel]="getFormControl('latitude')"
                             fieldLength="80"></lib-input-field>
 </div>
            <lib-type-selection *ngIf="projectWizzardState===0" (selectedType)="setTypeToModel($event)"
            label="Projekttyp"
            [choices]="projectEnumValues"
                                [formControlLabel]="getFormControl('type')"></lib-type-selection>
                <lib-input-textarea *ngIf="projectWizzardState===0" label="Beschreibung"
                                 [formControlLabel]="getFormControl('description')"
                                 fieldLength="65"></lib-input-textarea>

            <div class="project-line-separator"></div>
            <div *ngIf="projectWizzardState===1" class="project-create-header">
                <h4>Projektberandung</h4>
                <div style="display: flex;
    justify-content: space-between;
    width: 100%;">
                    <lib-primary-button type="button" variant="secondary-button" (click)="uploadProjectArea()" label="Hochladen"></lib-primary-button>
                    <lib-primary-button type="button" variant="secondary-button" (click)="drawProjectArea()" label="Einzeichnen"></lib-primary-button>
                </div>
            </div>
            <div class="button-row">
                <lib-primary-button *ngIf="projectWizzardState===1" type="submit" variant="primary-button" label="Speichern"></lib-primary-button>
                <lib-primary-button *ngIf="projectWizzardState===1" type="button" variant="secondary-button" (click)="previous()" label="Zurueck"></lib-primary-button>
                <lib-primary-button *ngIf="projectWizzardState===0" type="button" variant="primary-button" (click)="next()" label="Weiter"></lib-primary-button>
                <lib-primary-button *ngIf="projectWizzardState===0" type="button" variant="secondary-button" (click)="cancel()" label="Abbrechen"></lib-primary-button>
            </div>
        </form>
    </div>
    <div class="map-view">
        <div id="map"></div>
    </div>
</div>