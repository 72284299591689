import { Component, OnInit } from '@angular/core';
import {ActivatedRoute} from "@angular/router";
import {ProjectService} from "../../services/project.service";
import {Project} from "../../entities/Project";

@Component({
  selector: 'app-project-detail',
  templateUrl: './project-detail.component.html',
  styleUrls: ['./project-detail.component.scss']
})
export class ProjectDetailComponent implements OnInit {

  projectId!: string | null;
  project!: Project;

  constructor(private actRoute: ActivatedRoute,
              private projectService: ProjectService) { }

  ngOnInit() {
    this.actRoute.paramMap.subscribe(params => {
      this.projectId = params.get('id');
      this.projectService.findProjectByUuid(this.projectId).subscribe(
          item =>{
            this.project = item;
          }
      );
    });
  }

}
