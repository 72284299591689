<lib-shell (selectedLanguage)="changeLanguage($event)" (showUserModal)="openModal($event)" [isLoggedIn]="hasLoginUser">
        <lib-login-modal *ngIf="openUser===true && !hasLoginUser">
                <form [formGroup]="signupForm" (ngSubmit)="submit()">
                        <lib-input-field [formControlLabel]="getFormControl('email')"
                                         label="Email"
                                         inputType="email"
                                         [fieldError]="getFormControl('email').touched && getFormControl('email').errors!==null"
                                         errorText="Bitte geben Sie eine gültige Emailadresse an">
                        </lib-input-field>
                        <lib-input-field [formControlLabel]="getFormControl('password')"
                                         label="Password"
                                         inputType="password"
                                         fieldLength="96"
                                         [fieldError]="getFormControl('password').touched && getFormControl('password').errors!==null"
                                         errorText="Bitte geben Sie ein korrektes Password ein">
                        </lib-input-field>
                        <div class="button-list">
                        <lib-primary-button variant="secondary-button" (click)="cancel()" label="Cancel"></lib-primary-button>
                        <lib-primary-button variant="primary-button" label="Login"></lib-primary-button>
                        </div>
                </form>
        </lib-login-modal>
        <div *ngIf="openUser===true && hasLoginUser">
                {{openDropdown()}}
        </div>
</lib-shell>
<div *ngIf="advertising" class="advertising-container">

</div>
        <div id="mySidenav" class="sidenav">
                <a href="javascript:void(0)" class="closebtn" (click)="closeNav()">&times;</a>
                <a href="/user">Benutzerdetails</a>
                <a href="/" (click)="logout()">Logout</a>

        </div>
<router-outlet></router-outlet>
<lib-footer>
        <lib-footer-element>Datenschutz</lib-footer-element>
        <lib-footer-element>Impressum</lib-footer-element>
        <lib-footer-element>Sitemap</lib-footer-element>
        <lib-footer-element relativePath="/home">News</lib-footer-element>
</lib-footer>


